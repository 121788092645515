.App {
  /*background-color: #696969;*/
  /*color: white;*/
  background-color: #000;
  color: white;
  min-height: 100vh;
  padding-right: 2vw;
}

#my-name {
  width: 30%;
  padding-left: 55px;
}

.greeting {
  font-size: 75px;
}

.header-container {
  margin-top: 2em;
}

#body-container {
  margin-top: 5em;
}

.header-link-col {
  text-align: right;
}

.header-icon {
  padding-right: 0.5vw;
  margin-top: 1.25vh;
}

.App-link {
  color: #f0f0f0;
  margin-top: 1em;
}

.gradient-border-top-left {
  border-image: linear-gradient(to bottom right, white 00%, #696969 50%) 1;
  border-radius: 5px;
  border-width: 5px 0 0 5px; /* top, right, bottom, left */
  border-style: solid;
  padding: 5px;
}

.gradient-border-left {
  border-image: linear-gradient(to bottom right, white 00%, #696969 50%) 1;
  border-radius: 5px;
  border-width: 0 0 0 5px; /* top, right, bottom, left */
  border-style: solid;
  padding: 5px;
}

.gradient-border-bottom-right {
  border-image: linear-gradient(to top left, white 00%, #696969 50%) 1;
  border-radius: 5px;
  border-width: 0 5px 5px 0; /* top, right, bottom, left */
  border-style: solid;
  padding: 0px 25px;
}

#projects-header {
  margin-top: 5px;
  margin-bottom: 5px;
}

.project-row {
  margin-bottom: 15px;
}

.gradient-border-projects {
  border-image: linear-gradient(to top left, #696969 00%, white 50%) 1;
  border-radius: 5px;
  border-width: 1px 0 0 0; /* top, right, bottom, left */
  border-style: solid;
}

.project-link {

  color: white;
  text-decoration: none;

  border-image: linear-gradient(to right, #696969 00%, white 50%) 1;
  border-radius: 1px;
  border-width: 0 0 1px 0; /* top, right, bottom, left */
  border-style: solid;

  padding-left: 5px;
  padding-bottom: 5px;
}

#footer {
  text-align: right;
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 15px;
  margin-top: 3rem;
}

.download-link-container {
  padding-top: 3em;
}
.download-link {
  border: 1px solid white;
  padding: 0.45em;
  color: white;
  text-decoration: none;
}

.download-link:hover {
  color: gray;
  border: 1px solid gray;
}

.certificate {
  width: 20%;
}

@media only screen and (max-width: 991px) {
  #my-name {
    width: 87%;
    padding-left: 17px;
  }
  .header-link-col {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
  }
  #body-container {
    padding: 0 30px;
    margin-top: 2em;
  }
  .body-right.col-lg-2 {
    margin-top: 2em !important;
  }
  .certificate {
    width: 60%;
  }
  div.body-middle:not(div.body-middle.gradient-border-left) {
    text-align: center;
  }
  .download-link-container {
    text-align: center;
  }
  #footer {
    position: relative;
    
  }
}

a.download-link {
  font-weight: bold;
  transition: 0.5s;
}

a.download-link:hover {
  background-color: #071b9a;
  font-weight: bold;
  color: #fff;
}
body {
  background-color: #000 !important;
}